export default function Trash({
	fill = 'rgb(34, 43, 62)'
}) {
	return (
		<svg version="1.2" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="32" height="32">
			<g>
				<path id="trash" d="M9.91,17.24c-0.06,0.06-0.15,0.09-0.24,0.09H9c-0.18,0.01-0.32-0.13-0.33-0.31c0-0.01,0-0.01,0-0.02V9.67  c-0.01-0.18,0.12-0.33,0.3-0.34c0.01,0,0.02,0,0.03,0h0.67C9.85,9.32,9.99,9.46,10,9.64c0,0.01,0,0.01,0,0.02V17  C10,17.09,9.97,17.18,9.91,17.24z M12.58,17.24c-0.06,0.06-0.15,0.09-0.24,0.09h-0.67c-0.18,0.01-0.33-0.12-0.34-0.3  c0-0.01,0-0.02,0-0.03V9.67c-0.01-0.18,0.13-0.32,0.31-0.33c0.01,0,0.01,0,0.02,0h0.67c0.18-0.01,0.32,0.13,0.33,0.31  c0,0.01,0,0.01,0,0.02V17c0,0.09-0.03,0.18-0.09,0.24H12.58z M15.25,17.24c-0.06,0.06-0.15,0.09-0.24,0.09h-0.68  c-0.18,0.01-0.32-0.13-0.33-0.31c0-0.01,0-0.01,0-0.02V9.67c-0.01-0.18,0.13-0.32,0.31-0.33c0.01,0,0.01,0,0.02,0H15  c0.18-0.01,0.32,0.13,0.33,0.31c0,0.01,0,0.01,0,0.02V17c0,0.09-0.03,0.18-0.09,0.24H15.25z M10.18,5.45  c0.04-0.06,0.11-0.1,0.18-0.11h3.3c0.07,0.01,0.14,0.05,0.18,0.11l0.5,1.22H9.67L10.18,5.45z M19.33,7  c0.01-0.18-0.13-0.32-0.31-0.33c-0.01,0-0.01,0-0.02,0h-3.22l-0.73-1.74c-0.11-0.27-0.31-0.5-0.56-0.66  C14.25,4.1,13.96,4.01,13.67,4h-3.34c-0.29,0.01-0.58,0.1-0.82,0.27C9.26,4.43,9.06,4.66,8.95,4.93L8.22,6.67H5  C4.82,6.66,4.68,6.8,4.67,6.98c0,0.01,0,0.01,0,0.02v0.67C4.66,7.85,4.8,7.99,4.98,8C4.99,8,4.99,8,5,8h1v9.91  c-0.01,0.53,0.16,1.05,0.49,1.47C6.76,19.76,7.2,19.99,7.67,20h8.66c0.47-0.01,0.91-0.24,1.18-0.63c0.33-0.43,0.5-0.96,0.49-1.5V8h1  c0.18,0.01,0.32-0.13,0.33-0.31c0-0.01,0-0.01,0-0.02V7L19.33,7z" style={{ fill }} vectorEffect="non-scaling-stroke"/>
			</g>
		</svg>
	);
}
