import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {generateFeaturedImage, getDetails, updateDetails} from "../services/truckAssets";
import {toast} from "react-toastify";

const PhotosContext = createContext();

function PhotosProvider({ children }) {
  // various states
  // TODO: Move these into reducers?
  const [truckDetails, setTruckDetails] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  // This is to prevent replaced items from still being selected and to
  // update assets when their details have been edited.
  const updateSelectedItems = useCallback(
    (truckDetails) => {
      setSelectedItems((previousState) => {
        const stateCopy = [...previousState];

        const updatedState = stateCopy
          .map((asset) => {
            const matchedAsset = truckDetails.assets.find(
              (updatedAsset) => updatedAsset.id === asset.id
            );

            return matchedAsset ? matchedAsset : null;
          })
          .filter((asset) => asset);

        return updatedState;
      });
    },
    [setSelectedItems]
  );

  const isItemSelected = useCallback(
    (assetCheck) => {
      return (
        selectedItems.find((asset) => asset.id === assetCheck.id) !== undefined
      );
    },
    [selectedItems]
  );

  // basic details/truck info stuff
  const fetchTruckDetails = useCallback(
    async (searchText, hideLoader = false) => {
      if (!searchText) return;

      if (!hideLoader) {
        setLoading(true);
      }
      const truckDetails = await getDetails(searchText);

      setTruckDetails(truckDetails);

      updateSelectedItems(truckDetails);

      setLoading(false);

      return "success";
    },
    [updateSelectedItems]
  );

  // reload data when new search text is input
  useEffect(() => {
    (async () => {
      if (searchText) {
        await fetchTruckDetails(searchText, false);
      }
    })();
  }, [fetchTruckDetails, searchText]);

  // item selection things
  const setSelectedItem = useCallback(
    (assetCheck) => {
      if (isItemSelected(assetCheck)) {
        return;
      }

      setSelectedItems([...selectedItems, assetCheck]);
    },
    [isItemSelected, selectedItems]
  );

  const unsetSelectedItem = useCallback(
    (assetCheck) => {
      const assetIdIndex = selectedItems.findIndex(
        (asset) => asset.id === assetCheck.id
      );

      let newArray = [...selectedItems];

      if (assetIdIndex >= 0) {
        newArray.splice(assetIdIndex, 1);
      }

      setSelectedItems(newArray);
    },
    [selectedItems, setSelectedItems]
  );

  const clearSelectedItems = useCallback(() => {
    setSelectedItems([]);
  }, [setSelectedItems]);

  const updateTruckDetail = async function (isWholesale) {
    await updateDetails(truckDetails.vin, isWholesale);
    setTruckDetails({
      ...truckDetails,
      isWholesale,
    });
  };

  const generate = async function (vin) {
    await toast.promise(async () => {
      await generateFeaturedImage(vin);
      await fetchTruckDetails(vin, true);
    }, {
      pending: 'Generating featured image...',
      success: 'Feature image generated',
      error: 'Failed to generate featured image'
    });
  }

  // items that get shared from the hook
  const value = {
    loading,
    searchText,
    truckDetails,
    selectedItems,
    setSelectedItem,
    unsetSelectedItem,
    isItemSelected,
    clearSelectedItems,
    updateTruckDetail,
    generate,
    getTruckDetails: useCallback(
      async (searchText) => {
        setSearchText(searchText);
      },
      [setSearchText]
    ),
    refetch: useCallback(
      async (vin, hideLoader = true) => {
        await fetchTruckDetails(vin, hideLoader);
      },
      [fetchTruckDetails]
    ),
  };

  // create the context with the required values
  return (
    <PhotosContext.Provider value={value}>{children}</PhotosContext.Provider>
  );
}

function usePhotos() {
  const context = useContext(PhotosContext);

  if (context === undefined) {
    throw new Error("usePhotos must be used within a PhotosProvider");
  }

  return context;
}

export { PhotosProvider, usePhotos };
