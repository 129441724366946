import Loading from 'react-loading';
import cn from 'classnames';

import Icon from '../Icon';

export function ButtonLink({
	children,
	types = [],
	href,
	newWindow = false
}) {
	const typesClasses = types.map( type => `button--${ type }` );

	let linkAttributes = {};

	if ( newWindow ) {
		linkAttributes[ 'target' ] = '_blank';
	}

	return (
		<a className={ cn( 'button', typesClasses ) } href={ href } { ...linkAttributes }>
			{ children }
		</a>
	);
}

export default function Button({
	children,
	isDisabled,
	isLoading,
	types = [],
	icon = null,
	onClick = null,
}) {
	const typesClasses = types.map( type => `button--${ type }` );

	return (
		<button type="button" className={ cn( 'button', typesClasses, { 'button--disabled': isDisabled } ) } onClick={ isDisabled ? () => {} : onClick }>
			{ icon && <Icon icon={ icon } /> } { children }

			{ isLoading && (
				<div className="button-spinner">
					<Loading color="#676767" type="spin" width={ 24 } height={ 24 } />
				</div>
			)}
		</button>
	);
}

export function CloseButton({ className, onClick }) {
	return (
		<button
			type="button"
			className={ cn( 'button', 'button--close', className ) }
			onClick={ onClick }
		>
			Close <Icon icon="x" />
		</button>
	);
}
