import { MsalProvider } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";

import ConfirmModal from "../components/ConfirmModal";
import NetworkStatus from "../components/NetworkStatus";
import RefreshManager from "../components/RefreshManager";

import { msalInstance } from "../services/msal";
import { protectApiRequests } from "../services/webservices";

import { ConfirmProvider } from "../hooks/useConfirm";
import { LightboxProvider } from "../hooks/useLightbox";
import { PhotoContainerProvider } from "../hooks/usePhotoContainer";
import { PhotosProvider } from "../hooks/usePhotos";
import { UploadsProvider } from "../hooks/useUploads";

import "../styles/globals.scss";

import "react-toastify/dist/ReactToastify.css";

protectApiRequests();

function MyApp({ Component, pageProps }) {
  return (
    <MsalProvider instance={msalInstance}>
      <ConfirmProvider>
        <UploadsProvider>
          <PhotosProvider>
            <PhotoContainerProvider>
              <LightboxProvider>
                <NetworkStatus />

                <Component {...pageProps} />

                <ToastContainer
                  position="bottom-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  closeOnClick
                />

                {process.env.BUILD_ID !== "development" && <RefreshManager />}

                <ConfirmModal />
              </LightboxProvider>
            </PhotoContainerProvider>
          </PhotosProvider>
        </UploadsProvider>
      </ConfirmProvider>
    </MsalProvider>
  );
}

export default MyApp;
