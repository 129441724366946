import Modal from 'react-modal';
import classNames from 'classnames/bind';

import Button from '../Button';

import { useConfirm } from '../../hooks/useConfirm';

import styles from './ConfirmModal.module.scss';

Modal.setAppElement( '#__next' );

const cn = classNames.bind( styles );

const customStyles = {
	content: {
		zIndex: '3001',
		maxWidth: '600px',
		top: '50%',
		right: '0',
		bottom: 'auto',
		left: '0',
		margin: 'auto',
		padding: '50px',
		transform: 'translateY(-50%)',
	},
	overlay: {
		zIndex: '3000',
		backgroundColor: 'rgba(255, 255, 255, 0.9)'
	}
}

function ConfirmApproval({
	assets,
	children
}) {
	return (
		<div className={ cn( 'modal-body' ) }>
			<h2 className={ cn( 'modal__header' ) }>Ready to make these images live?</h2>

			<p className={ cn( 'modal__text' ) }>These images will be published to the production website:</p>

			{ assets.length > 0 && (
				<ul>
					{ assets.map( ( note, idx ) => (
						<li key={ idx }>{ note }</li>
					))}
				</ul>
			)}

			{ children }
		</div>
	);
}

function ConfirmDelete({ assets, children }) {
	return (
		<div className={ cn( 'modal-body' ) }>
			<h2 className={ cn( 'modal__header' ) }>Ready to delete the selected images?</h2>

			<p className={ cn( 'modal__text' ) }>The following items will be deleted. This cannot be undone.</p>

			{ assets.length > 0 && (
				<ul>
					{ assets.map( ( note, idx ) => (
						<li key={ idx }>{ note }</li>
					))}
				</ul>
			)}

			{ children }
		</div>
	);
}

function ConfirmNotification({ children }) {
	return (
		<div className={ cn( 'modal-body' ) }>
			<h2 className={ cn( 'modal__header' ) }>Your notification has been sent.</h2>

			{ children }
		</div>
	);
}

function NoAltText({ children, assets }) {
	return (
		<div className={ cn( 'modal-body' ) }>
			<h2 className={ cn( 'modal__header' ) }>Alt Text is required.</h2>

			<p className={ cn( 'modal__text' ) }>The following items do not have alt text. Please update them before approving for web.</p>

			{ assets.length > 0 && (
				<ul>
					{ assets.map( ( note, idx ) => (
						<li key={ idx }>{ note }</li>
					))}
				</ul>
			)}

			{ children }
		</div>
	);
}

function ConfirmSync({ children }) {
	return (
		<div className={ cn( 'modal-body' ) }>
			<h2 className={ cn( 'modal__header' ) }>Ready to sync the SAP truck to the website?</h2>
			<p className={ cn( 'modal__text' ) }>This will update the website with the latest SAP truck data.</p>
			{ children }
			</div>
	);
}

export default function ConfirmModal() {
	const {
		isOpen = false,
		proceed,
		cancel,
		type,
		notes
	} = useConfirm();

	return (
		<Modal
			isOpen={ isOpen }
			onRequestClose={ cancel }
			style={ customStyles }
		>
			{ type === 'approve' && (
				<ConfirmApproval assets={ notes }>
					<div className={ cn( 'modal-footer' ) }>
						<Button types={ [ 'filled' ] } onClick={ proceed }>Yes, approve</Button>
						<Button types={ [ 'text' ] } onClick={ cancel }>No, cancel</Button>
					</div>
				</ConfirmApproval>
			)}

			{ type === 'delete' && (
				<ConfirmDelete assets={ notes }>
					<div className={ cn( 'modal-footer' ) }>
						<Button types={ [ 'filled' ] } onClick={ proceed }>Yes, delete</Button>
						<Button types={ [ 'text' ] } onClick={ cancel }>No, cancel</Button>
					</div>
				</ConfirmDelete>
			)}

			{ type === 'notificationSent' && (
				<ConfirmNotification>
					<div className={ cn( 'modal-footer' ) }>
						<Button types={ [ 'filled' ] } onClick={ proceed }>OK</Button>
					</div>
				</ConfirmNotification>
			)}

			{ type === 'noAltText' && (
				<NoAltText assets={ notes }>
					<div className={ cn( 'modal-footer' ) }>
						<Button types={ [ 'filled' ] } onClick={ cancel }>OK</Button>
					</div>
				</NoAltText>
			)}

			{type === 'sync' && (
				<ConfirmSync>
					<div className={ cn( 'modal-footer' ) }>
						<Button types={ [ 'filled' ] } onClick={ proceed }>Yes, sync</Button>
						<Button types={ [ 'text' ] } onClick={ cancel }>No, cancel</Button>
					</div>
				</ConfirmSync>
			)}
		</Modal>
	);
}
