import { createContext, useCallback, useContext, useRef, useState } from 'react';

import { getPhotoContainers } from '../services/truckAssets';

const PhotoContainerContext = createContext();

function PhotoContainerProvider({ children }) {
	const [ photoContainers, setPhotoContainers ] = useState( [] );
	const [ tags, setTags ] = useState( [] );
	const dataFetched = useRef( false );
	const dataFetching = useRef( false );

	const fetchPhotoContainers = useCallback( async () => {
		if ( dataFetched.current || dataFetching.current ) {
			return;
		}

		try {
			dataFetching.current = true;

			const response = await getPhotoContainers();

			setPhotoContainers( response );
			setTags( [ ...new Set( response.filter( container => container.tags ).flatMap( container => container.tags ) ) ] );

			dataFetched.current = true;
		} catch( err ) {
			console.error( 'There was an error fetching the photo containers:', err );
		} finally {
			dataFetching.current = false;
		}
	}, [] );

	const value = {
		photoContainers,
		tags,
		getPhotoContainers: fetchPhotoContainers
	};

	return <PhotoContainerContext.Provider value={ value }>{ children }</PhotoContainerContext.Provider>;
}

function usePhotoContainer() {
	const context = useContext( PhotoContainerContext );

	if ( context === undefined ) {
		throw new Error( 'usePhotoContainer must be used within a PhotoContainerProvider' );
	}

	return context;
}

export { PhotoContainerProvider, usePhotoContainer };
