import { createContext, useCallback, useContext, useEffect, useState } from 'react';

const LightboxContext = createContext();

function LightboxProvider({ children }) {
	const [ activeLightbox, setActiveLightbox ] = useState( -1 );
	const [ imageUrls, setImageUrls ] = useState( [] );
	const [ imageCaptions, setImageCaptions ] = useState( [] );

	const imageUrl = contentUrl => `${ process.env.NEXT_PUBLIC_ASSETS_ENDPOINT }${ contentUrl }?w=800`;
	const photoIndex = contentUrl => imageUrls.indexOf( contentUrl );

	const openLightbox = useCallback( assetUrl => {
		setActiveLightbox( photoIndex( imageUrl( assetUrl ) ) );
	}, [ setActiveLightbox, photoIndex ] );

	const closeLightbox = useCallback( () => {
		setActiveLightbox( -1 );
	}, [ setActiveLightbox ] );

	const setLightboxImages = useCallback( assets => {
		if ( !assets || assets.length === 0 ) {
			return;
		}

		const filteredAssets = assets.filter( asset => asset.containerId );
		const allImages = filteredAssets.map( asset => imageUrl( asset.contentUrl ) );
		const allCaptions = filteredAssets.map( asset => asset.fileName );

		setImageUrls( [ ...allImages ] );
		setImageCaptions( [ ...allCaptions ] );
	}, [ setImageUrls, setImageCaptions ] );

	const sharedValue = {
		setLightboxImages,
		imageUrls,
		imageCaptions,
		openLightbox,
		closeLightbox,
		activeLightbox,
		setActiveLightbox
	};

	return <LightboxContext.Provider value={ sharedValue }>{ children }</LightboxContext.Provider>;
}

function useLightbox() {
	const context = useContext( LightboxContext );

	if ( context === undefined ) {
		throw new Error( 'useLightbox must be used within a LightboxProvider' );
	}

	return context;
}

export { LightboxProvider, useLightbox };
