import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

const UploadsContext = createContext();

function UploadsProvider({ children }) {
	const [ uploadQueue, setUploadQueue ] = useState( [] );
	const [ isUploadFinished, setIsUploadFinished ] = useState();

	const dataFetching = useRef( false );
	const dataFetched = useRef( false );

	const addToQueue = useCallback( queueString => {
		dataFetching.current = true;
		dataFetched.current = false;

		setUploadQueue( previousState => {
			console.log( [ ...previousState, queueString ] );

			return [ ...previousState, queueString ];
		});
	}, [ setUploadQueue ] );

	const removeFromQueue = useCallback( queueString => {
		setUploadQueue( previousState => {
			const stateCopy = [ ...previousState ];

			const matchedIndex = stateCopy.indexOf( queueString );

			if ( matchedIndex >= 0 ) {
				stateCopy.splice( matchedIndex, 1 );
			}

			if ( stateCopy.length === 0 ) {
				dataFetching.current = false;
				dataFetched.current = true;
			}

			return stateCopy;
		});
	}, [ setUploadQueue ] );

	useEffect( () => {
		if ( !dataFetched.current && dataFetching.current ) {
			setIsUploadFinished( false );
		}

		if ( dataFetched.current && !dataFetching.current && uploadQueue.length === 0 ) {
			setIsUploadFinished( true );
		}
	}, [ uploadQueue ] );

	const value = {
		isUploadFinished: useMemo( () => isUploadFinished, [ isUploadFinished ] ),
		addToQueue,
		removeFromQueue
	};

	return <UploadsContext.Provider value={ value }>{ children }</UploadsContext.Provider>;
}

function useUploads() {
	const context = useContext( UploadsContext );

	if ( context === undefined ) {
		throw new Error( 'useUploads must be used within an UploadsProvider' );
	}

	return context;
}

export { UploadsProvider, useUploads };
