import { createContext, useContext, useEffect, useState } from 'react';

const ConfirmContext = createContext();

function ConfirmProvider({ children }) {
	const [ confirm, setConfirm ] = useState({
		type: '',
		notes: [],
		isOpen: false,
		proceed: null,
		cancel: null
	});

	const [ needsCleanup, setNeedsCleanup ] = useState( false );

	const isConfirmed = ( type, notes = [] ) => {
		setNeedsCleanup( true );

		const promise = new Promise( ( resolve, reject ) => {
			setConfirm({
				type,
				notes,
				isOpen: true,
				proceed: resolve,
				cancel: reject
			});
		});

		return promise.then(
			() => {
				setConfirm({ ...confirm, isOpen: false });
				return true;
			},
			() => {
				setConfirm({ ...confirm, isOpen: false });
				return false;
			}
		);
	};

	useEffect( () => {
		return () => {
			if ( confirm.cancel && needsCleanup ) {
				confirm.cancel();
			}
		};
	}, [ confirm, needsCleanup ] );

	const value = {
		...confirm,
		isConfirmed
	};

	return <ConfirmContext.Provider value={ value }>{ children }</ConfirmContext.Provider>;
}

function useConfirm() {
	const context = useContext( ConfirmContext );

	if ( context === undefined ) {
		throw new Error( 'useConfirm must be used within a ConfirmProvider' );
	}

	return context;
}

export { ConfirmProvider, useConfirm };
