export default function Download() {
	return (
		<svg version="1.2" overflow="visible" preserveAspectRatio="none" viewBox="0 0 16 16" width="14" height="14">
			<g transform="translate(0, 0)">
				<g transform="translate(-0.000009999999999399378, 0) rotate(0)">
					<path style={{ 'strokeWidth': 0, 'strokeLinecap': 'butt', 'strokeLinejoin': 'miter', 'fill': 'rgba(18, 18, 18, 0.8)' }} d="M15.54545,6.54545h-4.90909v-6.54545h-3.27273v6.54545h-4.90909l6.54545,8.18182zM16.36364,13.09091v3.27273h-14.72727v-3.27273h-1.63636v4.90909h18v-4.90909z" vectorEffect="non-scaling-stroke"/>
				</g>
				<defs>
					<path id="path-16474383884952888" d="M15.54545,6.54545h-4.90909v-6.54545h-3.27273v6.54545h-4.90909l6.54545,8.18182zM16.36364,13.09091v3.27273h-14.72727v-3.27273h-1.63636v4.90909h18v-4.90909z" vectorEffect="non-scaling-stroke"/>
				</defs>
			</g>
		</svg>
	);
}
