export default function Upload({ color = 'rgb(34, 43, 62)' }) {
	return (
		<svg version="1.2" overflow="visible" preserveAspectRatio="none" viewBox="0 0 22 22" width="25" height="25">
			<g>
				<defs></defs>
				<rect display="none" fill="#FFA400" width="22" height="22" style={{ fill: color }} vectorEffect="non-scaling-stroke"/>
				<rect id="_x3C_Slice_x3E__126_" display="none" fill="none" width="22" height="22" style={{ fill: color }} vectorEffect="non-scaling-stroke"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M9,18h4v-8h6L11,0L3,10h6V18z M20,16v4H2v-4H0v6h22v-6H20z" style={{ fill: color }} vectorEffect="non-scaling-stroke"/>
			</g>
		</svg>
	);
}
