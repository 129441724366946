import { toast } from 'react-toastify';
import classNames from 'classnames/bind';

import Button from '../Button';

import { useRefresh } from '../../hooks/useRefresh';

import styles from './RefreshManager.module.scss';

const cn = classNames.bind( styles );

function UpdateMessage() {
	return (
		<div className={ cn( 'refresh-notice' ) }>
			<h2 className={ cn( 'refresh-notice__title' ) }>New version available</h2>

			<p>Refresh the app to get the latest changes.</p>

			<Button types={[ 'filled' ]} onClick={ () => window.location.reload() }>Refresh app</Button>
		</div>
	);
}

export default function RefreshManager() {
	const { shouldReload } = useRefresh();

	if ( shouldReload ) {
		toast( <UpdateMessage />, {
			toastId: 'update-notification',
			autoClose: false
		});
	}

	return <></>;
}
