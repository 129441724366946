import { useEffect, useRef } from 'react';
import { useNetworkState } from 'react-use';
import { toast } from 'react-toastify';

export default function NetworkStatus() {
	const networkState = useNetworkState();
	const toastId = useRef( null );

	const notify = () => toastId.current = toast.info( 'You are no longer connected to a network.', {
		autoClose: false,
		position: 'bottom-left',
		closeOnClick: false,
		closeButton: false,
		toastId: 'network-status',
		draggable: false
	});

	const dismiss = () => toast.dismiss( toastId.current );

	useEffect( () => {
		if ( networkState && !networkState.online ) {
			notify();
		} else {
			dismiss();
		}
	}, [ networkState ] );

	return <></>;
}
