export default function Photo({ width = 145, height = 145 }) {
	return (
		<svg preserveAspectRatio="none" viewBox="0 0 24 24" width={ width } height={ height }>
			<g>
				<circle cx="12" cy="12" r="3.2" style={{ fill: 'rgba(18, 18, 18, 0.2)' }} vectorEffect="non-scaling-stroke"/>
				<path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" style={{ fill: 'rgba(18, 18, 18, 0.2)' }} vectorEffect="non-scaling-stroke"/>
			</g>
		</svg>
	);
}
