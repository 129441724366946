import axios from 'axios';

import { acquireAccessToken } from './msal';

export function protectApiRequests() {
	axios.interceptors.request.use( async function( request ) {
		let accessToken;

		accessToken = await acquireAccessToken();

		request.headers.Authorization = `Bearer ${ accessToken }`;

		return request;
	}, function( error ) {
		return Promise.reject( error );
	});
}
