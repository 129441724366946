import { PublicClientApplication, LogLevel, EventType } from '@azure/msal-browser';

// const ua = window.navigator.userAgent;
// const msie = ua.indexOf( 'MSIE ' );
// const msie11 = ua.indexOf( 'Trident/' );
// const msedge = ua.indexOf( 'Edge/' );
// const firefox = ua.indexOf( 'Firefox' );

// const isIE = msie > 0 || msie11 > 0;
// const isEdge = msedge > 0;
// const isFirefox = firefox > 0;

const msalConfig = {
	auth: {
		clientId: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID,
		authority: process.env.NEXT_PUBLIC_AUTH_AUTHORITY,
		redirectUri: process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI,
		postLogoutRedirectUri: '/'
	},
	cache: {
		cacheLocation: 'sessionStorage',
		// storeAuthStateInCookie: isIE || isEdge || isFirefox
	},
	system: {
		loggerOptions: {
			loggerCallback: ( level, message, containsPii ) => {
				if ( containsPii ) {
					return;
				}

				switch ( level ) {
					case LogLevel.Error:
						console.error( message );
						return;
					case LogLevel.Info:
						console.info( message );
						return;
					case LogLevel.Verbose:
						console.debug( message );
						return;
					case LogLevel.Warning:
						console.warn( message );
						return;
					default:
						return;
				}
			}
		}
	}
};

const msalInstance = new PublicClientApplication( msalConfig );

if ( !msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0 ) {
	msalInstance.setActiveAccount( msalInstance.getAllAccounts()[ 0 ] );
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback( event => {
	if ( event.eventType === EventType.LOGIN_SUCCESS && event.payload.account ) {
		const account = event.payload.account;

		msalInstance.setActiveAccount( account );
	}
});

const loginRequest = {
	scopes: [ process.env.NEXT_PUBLIC_AUTH_SCOPE ]
};

async function acquireAccessToken() {
	const msalInstance = new PublicClientApplication( msalConfig );
	const activeAccount = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();

	if ( !activeAccount && accounts.length === 0 ) {
		throw new Error( 'Unauthorized: Unable to acquire token silently.' );
	}

	const request = {
		...loginRequest,
		account: activeAccount || accounts[ 0 ]
	};

	const authResult = await msalInstance.acquireTokenSilent( request );

	return authResult.accessToken;
}

export { msalConfig, msalInstance, loginRequest, acquireAccessToken };
