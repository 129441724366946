import Alert from './icons/Alert';
import ArrowDown from './icons/ArrowDown';
import Download from './icons/Download';
import Magnify from './icons/Magnify';
import MagnifyPlus from './icons/MagnifyPlus';
import Photo from './icons/Photo';
import Trash from './icons/Trash';
import Upload from './icons/Upload';
import X from './icons/X';

const iconMap = {
	'alert': Alert,
	'arrow-down': ArrowDown,
	'download': Download,
	'magnify': Magnify,
	'magnify-plus': MagnifyPlus,
	'photo': Photo,
	'trash': Trash,
	'upload': Upload,
	'x': X
};

export default function Icon({
	icon = '',
	width,
	height,
	color
}) {
	const Component = iconMap[ icon ];

	if ( !Component ) {
		return <></>;
	}

	return (
		<span className="icon">
			<Component width={ width } height={ height } color={ color } />
			<span className="sr-only">{ icon }</span>
		</span>
	);
}
