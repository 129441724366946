// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
const SENTRY_SAMPLE_RATE = process.env.SENTRY_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE;
const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || process.env.npm_package_name;
const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION || process.env.npm_package_version;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  release: `${ APP_NAME }@${ APP_VERSION }`,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_SAMPLE_RATE,
});
