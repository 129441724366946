export default function X() {
	return (
		<svg version="1.2" overflow="visible" preserveAspectRatio="none" viewBox="0 0 20 20" width="15" height="15">
			<g>
				<rect display="none" fill="#FFA400" width="20" height="20" style={{ 'fill': 'rgb(34, 43, 62)' }} vectorEffect="non-scaling-stroke" />
				<rect display="none" fill="none" width="20" height="20" style={{ 'fill': 'rgb(34, 43, 62)' }} vectorEffect="non-scaling-stroke" />
				<polygon fillRule="evenodd" clipRule="evenodd" points="20,3 17,0 10,7 3,0 0,3 7,10 0,17 3,20 10,13 17,20 20,17 13,10 " style={{ 'fill': 'rgb(34, 43, 62)' }} vectorEffect="non-scaling-stroke" />
			</g>
		</svg>
	);
}
